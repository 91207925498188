import * as z from 'zod';
import { customDataSchema, redemptionEarnSchema } from '../common.schema';
import {
  baseGiftCardOrderItemDataSchema,
  digitalGiftCardFulfillmentDataSchema,
} from './gift-card';

export const giftCardOrderItemDataSchema =
  baseGiftCardOrderItemDataSchema.extend({
    fulfillmentData: digitalGiftCardFulfillmentDataSchema.optional(),
  });
export type GiftCardOrderItemData = z.infer<typeof giftCardOrderItemDataSchema>;

// TODO update later
export const membershipInformationSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  number: z.string(),
});
export const pointsTransferOrderItemDataSchema = z.object({
  displayValue: z.number().optional(),
  productImageUrl: z.string(),
  autosweep: z.boolean(),
  loyaltyCurrencyName: z.string(),
  loyaltyCurrencyShortName: z.string(),
  loyaltyProgramId: z.string(),
  membership: membershipInformationSchema,
  membershipInformation: membershipInformationSchema,
  redemptionEarn: redemptionEarnSchema.optional(),
  productName: z.string(),
  programShortName: z.string(),
  tenantCurrencyName: z.string(),
  tenantCurrencyShortName: z.string(),
  transferAmount: z.number(),
  processingDelay: z.number(),
  customData: customDataSchema.optional(),
});
// export const orderItemDataSchema = z.union([
//   cashRedemptionOrderItemDataSchema,
//   giftCardOrderItemDataSchema,
//   // physicalGiftCardOrderItemDataSchema,
//   // merchandiseOrderItemDataSchema,
//   // pointsTransferOrderItemMetadataSchema,
// ]);

export const orderItemDataSchema = giftCardOrderItemDataSchema;
