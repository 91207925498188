import * as z from 'zod';

import {
  ancillariesSchema,
  passengerInformationFormSchema,
} from '@/features/checkout/forms/flights-validation-schema';
import { carSchema, carTermsResponseSchema } from '../cars/car.schema';
import { customDataSchema, redemptionEarnSchema } from '../common.schema';
import { flightSegmentSchema } from '../flights/prices/prices.schema';
import { flightRule } from '../flights/term-condition';
import {
  breakfastOptionsSchema,
  carDriverSchema,
  carFeeSchema,
  carSearchParamsSchema,
  recipientSchema,
} from '../shopping-cart-item.schema';
import { priceBreakdownSchema } from '../travel/price-breakdown.schema';
import { cashRedemptionOrderItemDataSchema } from './cash-redemption';
import {
  notificationSchema,
  orderItemStatusSchema,
  orderItemTypeEnum,
} from './common';
import {
  giftCardOrderItemDataSchema,
  membershipInformationSchema,
  orderItemDataSchema,
  pointsTransferOrderItemDataSchema,
} from './order-item-data';

export const orderItemAttributesSchema = z.object({
  userId: z.string(),
  status: orderItemStatusSchema,
  description: z.string(),
  quantity: z.number(),
  cashPaid: z.number(),
  cashAmount: z.number(),
  pointsPaid: z.number(),
  pointsAmount: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  data: orderItemDataSchema,
});

export const orderItemBaseSchema = z.object({
  id: z.string(),
  type: orderItemTypeEnum,
  // I will re-enable this again
  // but will confirm with sir Duc later
  notifications: z.array(notificationSchema),
});

export const giftCardOrderItemSchema = orderItemBaseSchema
  .merge(
    orderItemAttributesSchema.extend({ data: giftCardOrderItemDataSchema }),
  )
  .extend({
    type: orderItemTypeEnum.extract(['gift_card_order_item']),
  });

export const cashRedemptionOrderItemSchema = orderItemBaseSchema
  .merge(
    orderItemAttributesSchema.extend({
      data: cashRedemptionOrderItemDataSchema,
    }),
  )
  .extend({
    type: orderItemTypeEnum.extract(['cash_redemption_order_item']),
  });

export const pointsTransferOrderItemSchema = orderItemBaseSchema
  .merge(
    orderItemAttributesSchema.extend({
      data: pointsTransferOrderItemDataSchema,
    }),
  )
  .extend({
    type: orderItemTypeEnum.extract(['points_transfer_order_item']),
  });

export const sustainabilityOrderItemSchema = orderItemBaseSchema
  .merge(orderItemAttributesSchema)
  .extend({
    type: orderItemTypeEnum.extract(['sustainability_order_item']),
    data: z.object({
      redemptionEarn: redemptionEarnSchema.optional(),
      loyaltyProgramId: z.string(),
      capabilityId: z.string(),
      transferAmount: z.number(),
      productName: z.string(),
      productImageUrl: z.string(),
      loyaltyCurrencyName: z.string(),
      membership: membershipInformationSchema,
      customData: customDataSchema.optional(),
    }),
  });

export const cashbackOrderItemSchema = orderItemBaseSchema
  .merge(orderItemAttributesSchema)
  .extend({
    type: orderItemTypeEnum.extract(['cash_redemption_order_item']),
    data: z.object({
      redemptionEarn: redemptionEarnSchema.optional(),
      loyaltyProgramId: z.string(),
      capabilityId: z.string(),
      cashAmount: z.number(),
      productName: z.string(),
      productImageUrl: z.string(),
      loyaltyCurrencyName: z.string(),
      membership: membershipInformationSchema,
      customData: customDataSchema.optional(),
    }),
  });

export const cryptoOrderItemSchema = pointsTransferOrderItemSchema.extend({
  type: orderItemTypeEnum.extract(['crypto_order_item']),
  data: z.object({
    redemptionEarn: redemptionEarnSchema.optional(),
    loyaltyProgramId: z.string(),
    capabilityId: z.string(),
    transferAmount: z.number(),
    productName: z.string(),
    productImageUrl: z.string(),
    loyaltyCurrencyName: z.string(),
    membership: membershipInformationSchema,
    customData: customDataSchema.optional(),
  }),
});

export const voucherOrderItemSchema = pointsTransferOrderItemSchema.extend({
  type: orderItemTypeEnum.extract(['voucher_order_item']),
  data: z.object({
    redemptionEarn: redemptionEarnSchema.optional(),
    loyaltyProgramId: z.string(),
    capabilityId: z.string(),
    transferAmount: z.number(),
    productName: z.string(),
    productShortName: z.string(),
    productImageUrl: z.string(),
    loyaltyCurrencyName: z.string(),
    membership: membershipInformationSchema,
    customData: customDataSchema.optional(),
  }),
});

export const merchandiseOrderItemSchema = pointsTransferOrderItemSchema.extend({
  type: orderItemTypeEnum.extract(['merchandise_order_item']),
});

export const baseTravelOrderItemSchema = orderItemBaseSchema
  .merge(orderItemAttributesSchema)
  .extend({
    type: orderItemTypeEnum.extract(['travel_booking_order_item']),
    bookingStartAt: z.string(),
    bookingEndAt: z.string(),
    supplierReferenceId: z.string().optional(),
    data: z.object({
      redemptionEarn: redemptionEarnSchema.optional(),
      productName: z.string(),
      tierId: z.number(),
      bookingKey: z.string(),
      bookingTransactionId: z.string(),
      travelType: z.enum(['flights', 'hotels', 'cars']),
      status: orderItemStatusSchema,
      customData: customDataSchema.optional(),
    }),
  });

export const flightOrderItemSchema = baseTravelOrderItemSchema.extend({
  data: z.object({
    ancillaries: ancillariesSchema,
    redemptionEarn: redemptionEarnSchema.optional(),
    rules: z.array(flightRule),
    productName: z.string(),
    productImageUrl: z.string(),
    locale: z.string(),
    tierId: z.number(),
    currency: z.string(),
    recipient: recipientSchema,
    passengers: z.array(passengerInformationFormSchema),
    bookingKey: z.string(),
    cabinClass: z.string(),
    flightType: z.enum(['rt', 'ow']),
    travelType: z.literal('flights'),
    allSegments: z.array(flightSegmentSchema).nonempty(),
    paymentMethod: z.object({
      id: z.string(),
      saveCard: z.boolean(),
      returnUrl: z.string().optional(),
    }),
    originSegments: z.array(flightSegmentSchema),
    orderSourceType: z.string(),
    bookingTransactionId: z.string(),
    travelConfirmationId: z.string().nullable(),
    status: orderItemStatusSchema,
    customData: customDataSchema.optional(),
  }),
});

export type FlightOrderItemSchema = z.infer<typeof flightOrderItemSchema>;

export const hotelOrderItemSchema = baseTravelOrderItemSchema.extend({
  data: z.object({
    travelConfirmationId: z.string().optional(),
    redemptionEarn: redemptionEarnSchema.optional(),
    breakfastInfo: breakfastOptionsSchema,
    bookingKey: z.string(),
    bookingTransactionId: z.string(),
    cancellationPolicy: z
      .object({
        freeCancellationBefore: z.string().optional(),
        nonRefundableFrom: z.string().optional(),
        remarks: z.string().optional(),
      })
      .optional()
      .nullable(),
    currency: z.string(),
    freeCancellation: z.boolean(),
    guest: z.object({
      email: z.string(),
      phone: z.string(),
      lastName: z.string(),
      firstName: z.string(),
      salutation: z.string(),
      specialRequest: z.string().optional(),
    }),
    heroImageUrl: z.string(),
    hotel: z.object({
      adultCount: z.string(),
      checkin: z.string(),
      checkout: z.string(),
      childCount: z.string(),
      destinationId: z.string(),
      guests: z.string(),
      hotelAddress: z.string(),
      hotelId: z.string(),
      hotelName: z.string(),
      roomCount: z.string(),
      roomDescription: z.string(),
    }),
    importantInformation: z.object({
      specialCheckInInstructions: z.string().nullable(),
      checkInInstructions: z.string().nullable(),
      feesMandatory: z.string().nullable(),
      feesOptional: z.string().nullable(),
      knowBeforeYouGo: z.string().nullable(),
      specialInstructions: z.string().nullable(),
    }),
    locale: z.string(),
    orderSourceType: z.string(),
    productName: z.string(),
    recipient: z.object({
      email: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      phoneNumber: z.string(),
      salutation: z.string().optional(),
    }),
    status: orderItemStatusSchema,
    tierId: z.number(),
    travelType: z.literal('hotels'),
    refundCashAmountInCents: z.number(),
    refundPointsAmount: z.number(),
    originalMetadata: z
      .object({
        name: z.null(),
        city: z.string(),
        state: z.null(),
        country: z.string(),
      })
      .optional(),
    priceBreakdown: priceBreakdownSchema,
    customData: customDataSchema.optional(),
  }),
});

export type HotelOrderItem = z.infer<typeof hotelOrderItemSchema>;

export const carOrderItemSchema = baseTravelOrderItemSchema.extend({
  data: z.object({
    redemptionEarn: redemptionEarnSchema.optional(),
    travelConfirmationId: z.string().nullable(),
    productName: z.string(),
    status: orderItemStatusSchema,
    driver: carDriverSchema,
    car: carSchema,
    terms: carTermsResponseSchema,
    locale: z.string(),
    tierId: z.number(),
    currency: z.string(),
    bookingKey: z.string(),
    costInUsd: z.number(),
    travelType: z.string(),
    clientMargin: z.number(),
    exchangeRate: z.number(),
    supplierRate: z.number(),
    ascendaMargin: z.number(),
    paymentMethod: z.object({ id: z.string(), saveCard: z.boolean() }),
    cashPaidInUsd: z.number(),
    costInCurrency: z.number(),
    orderSourceType: z.string(),
    markup: z.number(),
    location: z.object({
      supplierLat: z.string(),
      supplierLng: z.string(),
      supplierAddress: z.string(),
      dropoffSupplierLat: z.string(),
      dropoffSupplierLng: z.string(),
      dropoffSupplierAddress: z.string(),
    }),
    recipient: z.object({
      salutation: z.string().optional(),
      email: z.string(),
      lastName: z.string(),
      firstName: z.string(),
      phoneNumber: z.string(),
    }),
    bookingTransactionId: z.string(),
    search: carSearchParamsSchema,
    fees: z.array(carFeeSchema).nullish(),
    customData: customDataSchema.optional(),
  }),
});

export type CarOrderItemDriverSchema = z.infer<typeof carDriverSchema>;

export type CarOrderItemSchema = z.infer<typeof carOrderItemSchema>;

// this can't be discriminated because it's flightOrderItem and hotelOrderItem share the same 'type' attribute
export const orderItemSchema = z.union([
  giftCardOrderItemSchema,
  sustainabilityOrderItemSchema,
  cashbackOrderItemSchema,
  pointsTransferOrderItemSchema,
  cryptoOrderItemSchema,
  carOrderItemSchema,
  flightOrderItemSchema,
  hotelOrderItemSchema,
  voucherOrderItemSchema,
  merchandiseOrderItemSchema,
]);

export const travelItemSchema = z.union([
  flightOrderItemSchema,
  hotelOrderItemSchema,
]);

export type OrderItem = z.infer<typeof orderItemSchema>;

export type GiftCardOrderItem = z.infer<typeof giftCardOrderItemSchema>;

export const isFlightOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is FlightOrderItemSchema => {
  return (
    orderItem?.type === 'travel_booking_order_item' &&
    orderItem?.data.travelType === 'flights'
  );
};

export const isHotelOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is HotelOrderItem => {
  return (
    orderItem?.type === 'travel_booking_order_item' &&
    orderItem?.data.travelType === 'hotels'
  );
};

export const isCarOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is CarOrderItemSchema => {
  return (
    orderItem?.type === 'travel_booking_order_item' &&
    orderItem?.data.travelType === 'cars'
  );
};
