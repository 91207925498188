import * as z from 'zod';

export const orderItemTypeEnum = z.enum([
  'travel_booking_order_item',
  'gift_card_order_item',
  'points_transfer_order_item',
  'crypto_order_item',
  'sustainability_order_item',
  'cash_redemption_order_item',
  'voucher_order_item',
  'merchandise_order_item',
  'order',
]);

export const orderItemTypeToTypeInPath = {
  gift_card_order_item: 'gift-cards',
  points_transfer_order_item: 'frequent-traveler-programs',
  crypto_order_item: 'crypto',
  sustainability_order_item: 'sustainability',
  cash_redemption_order_item: 'cashback',
  voucher_order_item: 'uber-credit',
  merchandise_order_item: 'merchandise',
} as const;

export type OrderItemType = z.infer<typeof orderItemTypeEnum>;

export const orderAddressSchema = z.object({
  city: z.string().nullable(),
  countryCode: z.string().nullable(),
  line1: z.string().nullable(),
  line2: z.string().nullable(),
  postalCode: z.string().nullable(),
  state: z.string().nullable(),
});

// https://github.com/Kaligo/nydus_network/blob/a0b0e398fd73ec3ab8dd046b5024b072606145d8/app/models/order_item.rb#L53
export const orderItemStatusSchema = z.enum([
  'cancelled', // For self cancellation
  'processing_cancellation',
  'queued_for_processing', // AR only
  'processing',
  'fulfilled',
  'failed',
  'pending',
]);

export type OrderItemStatus = z.infer<typeof orderItemStatusSchema>;

export const notificationChannelSchema = z.enum([
  'ascenda_email',
  'partner_email',
  'partner_sms',
  'partner_push',
  'partner_widget',
  'supplier_mms',
]);

export const notificationSchema = z.object({
  id: z.string(),
  type: z.literal('notification'),
  channel: notificationChannelSchema,
  eventName: z.string(),
});
