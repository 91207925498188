'use client';
import { cn } from '@/utils/tailwind';

interface ProductSkeletonProps {
  className?: string;
  variant?: 'gift-card' | 'default';
}

export default function ListingProductSkeleton({
  variant = 'default',
  className,
}: ProductSkeletonProps) {
  return (
    <div
      className={cn(
        'flex flex-col rounded border border-neutral-400',
        className,
      )}
    >
      {variant === 'gift-card' && (
        <div
          className={cn(
            'flex h-[220px] flex-shrink-0 animate-pulse items-center justify-center rounded-t',
            'bg-white px-12 py-8',
            'border-b-solid border-b border-b-neutral-400',
            'lg:h-[170px]',
          )}
        >
          <div
            className={cn(
              'h-[148px] w-full rounded-lg bg-neutral-300',
              'lg:h-[115px]',
            )}
          ></div>
        </div>
      )}
      {variant === 'default' && (
        <div className="h-[179px] flex-shrink-0 animate-pulse bg-neutral-300 lg:h-[197px]"></div>
      )}
      <div className="flex flex-grow animate-pulse flex-col rounded-b bg-neutral-100 px-6 py-[30px] lg:px-4 lg:py-5">
        <div className="mb-4 h-4 w-3/4 rounded bg-neutral-300 lg:w-3/5"></div>
        <div className="h-4 w-full rounded bg-neutral-300"></div>
      </div>
    </div>
  );
}
